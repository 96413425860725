import React, { useEffect, useState } from 'react';
import SearchModal from '../search/searchModal';
import { createWedding, getWeddingDetail, updateWeddingDetails } from '../../actions/weddingAction';
import LoadingSpinner from '../loader';
import { useLocation } from '@reach/router';
import { getLoggedInUserId } from '../../actions/authAction';
import { toast } from 'react-toastify';
import './weddingStyles.css';
import PlacesAutocomplete from '../extra-components/placesAutocomplete';
import moment from 'moment';

export default function AddNewWeddings() {
  const [marriageDate, setMarriageDate] = useState('');
  const [baraatDestination, setBaraatDestination] = useState({});
  const [baraatFromLocation, setBaraatFromLocation] = useState({});
  const [profileType, setProfileType] = useState('');
  const [isGroomSearchModalOpen, setGroomSearchModalOpen] = useState(false);
  const [isBrideSearchModalOpen, setBrideSearchModalOpen] = useState(false);
  const [selectedGroom, setSelectedGroom] = useState({});
  const [selectedBride, setSelectedBride] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [weddingDetails, setWeddingDetails] = useState({});
  const loggedInUserId = getLoggedInUserId();
  const location = useLocation();
  const urlParams = new URLSearchParams(decodeURI(location.search));
  const weddingIdParam = urlParams.get('weddingId');

  useEffect(() => {
    if (weddingIdParam) {
      setIsEditMode(true);
      fetchWeddingDetails(weddingIdParam);
    }
  }, [weddingIdParam]);

  const fetchWeddingDetails = async (weddingId) => {
    try {
      const response = await getWeddingDetail({ weddingId });
      setWeddingDetails(response.event);
      setProfileType(response?.event?.side?.toLowerCase());
      setMarriageDate(response?.event?.start_date);
      setBaraatDestination(response?.event?.baraat_destination);
      setBaraatFromLocation(response?.event?.baraat_from_location);
      setSelectedGroom({
        id: response.event.groom_node_id,
        name: response.event.metadata.groom_node.name,
        gautra: response.event.metadata.groom_node.gautra,
        subcaste: response.event.metadata.groom_node.subcaste,
        village: response.event.metadata.groom_node.village,
      });
      setSelectedBride({
        id: response.event.bride_node_id,
        name: response.event.metadata.bride_node.name,
        gautra: response.event.metadata.bride_node.gautra,
        subcaste: response.event.metadata.bride_node.subcaste,
        village: response.event.metadata.bride_node.village,
      });
    } catch (error) {
      console.error('Error fetching wedding details:', error);
      toast.error('Error fetching wedding details');
    }
  };

  const handleSearchModalOpen = (gender) => {
    if (gender === 'male') {
      setGroomSearchModalOpen(true);
    } else if (gender === 'female') {
      setBrideSearchModalOpen(true);
    }
  };

  const addWedding = async () => {
    if (!selectedGroom?.name?.trim() || !selectedBride?.name?.trim()) {
      setLoading(false);
      toast.error('Please select a groom and a bride');
      return;
    }

    try {
      setLoading(true);
      const requestData = {
        created_by: loggedInUserId,
        bride_node_id: selectedBride?.id,
        groom_node_id: selectedGroom?.id,
        side: profileType.toUpperCase(),
        start_date: marriageDate,
        baraat_destination: baraatDestination,
        baraat_from_location: baraatFromLocation,
        // name: selectedGroom?.name,
      };

      let url = '';
      if (isEditMode) {
        if (weddingDetails?.wedding_card) {
          requestData.wedding_card = weddingDetails.wedding_card;
        }
        const result = await updateWeddingDetails(requestData, weddingIdParam);
        if (result.status) {
          console.log('Wedding event updated successfully:', result);
          url = `/weddings/uploadWeddingCard?weddingId=${weddingIdParam}`;
          toast.success('Wedding Details Updated Successfully');
        } else toast.error('Error: ', result?.error_message);
      } else {
        const result = await createWedding(requestData);
        if (result.status) {
          console.log('Wedding event created successfully:', result);
          url = `/weddings/uploadWeddingCard?weddingId=${result.event_id}`;
          toast.success('Wedding Created Successfully');
        } else {
          toast.error('Error: ', result?.error_message);
        }
      }
      window.location.href = url;
    } catch (error) {
      console.error('Failed to save wedding event:', error);
      toast.error('Failed to save wedding event');
      // window.alert("Failed to save wedding event")
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="form-container">
        {isGroomSearchModalOpen && (
          <SearchModal
            onClose={() => setGroomSearchModalOpen(false)}
            gender="male"
            onSelectUser={(groomData) => {
              setSelectedGroom(groomData);
              console.log('SelectedGroom Data ', groomData);
            }}
          />
        )}
        {isBrideSearchModalOpen && (
          <SearchModal
            onClose={() => setBrideSearchModalOpen(false)}
            gender="female"
            onSelectUser={(brideData) => {
              setSelectedBride(brideData);
              console.log('SelectedBride Data', brideData);
            }}
          />
        )}
        <div>
          <h2>Groom & Bride Details</h2>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              addWedding();
            }}
          >
            <div>
              <label>
                <input
                  type="radio"
                  name="profileType"
                  value="groom"
                  checked={profileType === 'groom'}
                  onChange={(event) => {
                    setProfileType(event.target.value);
                  }}
                  required
                />
                वर
              </label>
              <label>
                <input
                  type="radio"
                  name="profileType"
                  value="bride"
                  checked={profileType === 'bride'}
                  onChange={(event) => {
                    setProfileType(event.target.value);
                  }}
                  required
                />
                वधु
              </label>
            </div>

            <div>
              <label>
                वर का नाम खोजें
                <input
                  className="input-class"
                  type="text"
                  value={
                    selectedGroom?.name
                      ? `${selectedGroom.name || ''} ${
                          selectedGroom.father_name ? 'पुत्र ' + selectedGroom.father_name : ''
                        } ${selectedGroom.subcaste || selectedGroom.gautra || ''}${
                          selectedGroom.village ? ', निवासी: ' + selectedGroom.village : ''
                        }`
                      : ''
                  }
                  placeholder=" वर का नाम खोजें*"
                  onClick={() => handleSearchModalOpen('male')}
                  required
                />
              </label>
            </div>

            <div>
              <label>
                वधु का नाम खोजें
                <input
                  className="input-class"
                  type="text"
                  value={
                    selectedBride?.name
                      ? `${selectedBride.name || ''} ${
                          selectedBride.father_name ? 'बेटी ' + selectedBride.father_name : ''
                        } ${selectedBride.subcaste || selectedBride.gautra || ''}${
                          selectedBride.village ? ', निवासी: ' + selectedBride.village : ''
                        }`
                      : ''
                  }
                  placeholder="वधु का नाम खोजें*"
                  onClick={() => handleSearchModalOpen('female')}
                  required
                />
              </label>
            </div>
            <div>
              <label>
                शादी की तारीख
                <input
                  className="input-class"
                  type="date"
                  value={moment(marriageDate).format('YYYY-MM-DD')}
                  // value={marriageDate}
                  onChange={(e) => {
                    let selectedDate = new Date(e.target.value);
                    setMarriageDate(selectedDate);
                  }}
                  required
                />
              </label>
            </div>
            <div>
              <label>
                बारात कहाँ से आएगी
                <PlacesAutocomplete
                  type="baraatFromLocation"
                  label="Baraat From Location"
                  placeAddressObject={baraatFromLocation}
                  setSelectedPlace={setBaraatFromLocation}
                  setChange={true}
                />
              </label>
            </div>
            <div>
              <label>
                बारात कहाँ जाएगी
                <PlacesAutocomplete
                  type="baraatDestination"
                  label="Baraat Destination"
                  placeAddressObject={baraatDestination}
                  setSelectedPlace={setBaraatDestination}
                  setChange={true}
                />
              </label>
            </div>

            <button type="submit">{isEditMode ? 'Update' : 'Continue'}</button>
          </form>
        </div>
        {isLoading && <LoadingSpinner message={isEditMode ? 'Updating Wedding Details' : 'Creating Wedding'} />}
      </div>
    </>
  );
}
